import React from "react"
import { Link  } from "gatsby"
import { Container, Row, Col } from 'react-bootstrap'

export default function Paginate({ currentPage, numPages, pageLinks }) {
    const style = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: "white",
        backgroundColor: "#27476E",
        width: 95,
        height: 40,
        borderRadius: 4
    }

    // Don't show prev page link if first page
    let prevPageLink = currentPage - 1 < 1 ? (
        null
    ) : (
        currentPage - 1 === 1 ?
            <Link id="page" style={style} to="/news">
                Previous
            </Link> :
            <Link id="page" style={style} to={ `/news-${currentPage - 1}` }>
                Previous
            </Link>
    )
    // Don't show next page link if last page
    let nextPageLink = currentPage + 1 > numPages ? (
        null
    ) : (
        <Link id="page" style={style} to={ `/news-${currentPage + 1}` }>Next
        </Link>
    )

    return (
        <Container>
            <Row className="justify-content-around text-center">
                <Col lg={2} className="my-3 d-flex justify-content-center">{ prevPageLink }</Col>
                <Col lg={8} className="my-3 d-flex justify-content-center" style={{flexWrap: 'wrap'}}>{ pageLinks }</Col>
                <Col lg={2} className="my-3 d-flex justify-content-center">{ nextPageLink }</Col>
            </Row>
        </Container>
    )
}
