import React from "react"
import { Container, Row, Col  } from 'react-bootstrap'
import { graphql, Link  } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Archives from "../components/archives"
import Paginate from "../components/paginate"
import "../styles/bootstrap.scss"
import Article from "../components/article"
import "animate.css/animate.min.css";

export default function allPosts({ pageContext, data }) {
    const { posts  } = data.news
    const { currentPage, numPages, uniqueDates, countPosts } = pageContext

    // Links for all pagination
    const pageLinks = []
    for (var i = 1; i <= numPages; i++) {
        let url = i === 1 ? `/news` : `/news-${i}`
        pageLinks.push(<Link id='page' key={i} className="mr-2 mb-1 d-flex align-items-center justify-content-center" style={{color: "white", backgroundColor: (currentPage === i ? "#FFC000" : "#27476E"), width: 60, height: 40, borderRadius: 4}} to={url}>{i}</Link>)
    }

    // Preprocess content
    const articles = posts.map(post => {
        return (
            <Article key={post.id} post={post} preview={true} />
        )
    })

    return (
        <Layout>
            <Seo title="News" />
            <img className="parallax news" src="/news.jpeg" alt="Parallax"/>
            <div className="banner-cover" />
            <div className="banner animate__animated fadeInDownSmall">
                <h1 className="display-3"><b>News</b></h1>
            </div>
            <Container fluid className="bg-white" style={{padding: '3% 1px 1px', boxShadow: 'inset 0 5px 5px #bebebe'}}>
                <Container className="mt-0">
                    <Row className="justify-content-between">
                        <Col lg={8}>
                            {articles}
                            <Paginate
                                currentPage={ currentPage }
                                numPages={ numPages }
                                pageLinks={ pageLinks }
                            />
                        </Col>
                        <Col lg={3}>
                            <Archives
                                countPosts={countPosts}
                                uniqueDates={ uniqueDates }
                            />
                        </Col>
                    </Row>
                </Container>
            </Container>
        </Layout>
    )
}

export const pageQuery = graphql`
    query AllPostsQuery($skip: Int!, $limit: Int!) {
        news: allMarkdownRemark(
            sort: { fields: [frontmatter___date], order: DESC  },
            skip: $skip,
            limit: $limit,
        ) {
            posts: nodes {
                fields {
                    slug
                }
                frontmatter {
                    date(formatString: "LL")
                    title
                    location
                    image
                    imageSize
                }
                html
                excerpt(pruneLength: 500)
                id
            }
        }
    }
`
